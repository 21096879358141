.fade-in {
  opacity: 1;
  transition: opacity 0.3s ease-in;
}

.fade-out {
  opacity: 0;
  transition: opacity 0.3s ease-out;
}

.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.75);
  z-index: 1000;
  display: flex;
  justify-content: center;
  align-items: center;
}

.modal-content {
  position: relative;
  background: white;
  padding: 20px;
  border-radius: 8px;
  outline: none;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.3);
  width: 100%;
  max-width: 900px;
  z-index: 1001;
}

.table-auto {
  width: 100%;
  border-collapse: collapse;
}

.table-auto th,
.table-auto td {
  border: 1px solid #ddd;
  padding: 8px;
}

.table-auto th {
  background-color: #f2f2f2;
  text-align: center;
}

.bg-primary-green {
  background-color: #28a745; /* Vert */
}

.bg-primary-dark {
  color: #343a40; /* Gris foncé */
}

@media (max-width: 768px) {
  .modal-content {
    padding: 15px;
  }
}

.p-4 {
  padding: 1rem;
}

.border {
  border: 1px solid #ddd;
}

.rounded {
  border-radius: 0.5rem;
}

.shadow-lg {
  box-shadow: 0 10px 15px rgba(0, 0, 0, 0.1);
}

.space-y-2 > * + * {
  margin-top: 0.5rem;
}

.space-y-4 > * + * {
  margin-top: 1rem;
}

.bg-white {
  background-color: white;
}

.text-primary-dark {
  color: #343a40;
}

.text-primary-green {
  color: #28a745;
}

.mb-4 {
  margin-bottom: 1rem;
}

.mb-2 {
  margin-bottom: 0.5rem;
}

.mb-1 {
  margin-bottom: 0.25rem;
}
